const config = {
  boardLeadManagementId: 2606091425,
  boardClasesDePracticaId: 2606105863,
  boardEnglishTeacherCv: 2606106178,
  boardEveningEnglishClasses: 2606106015,
  groupId: 'topics',
  status: { label: 'New Application' },
  date: { date: new Date().toISOString().split('T')[0] },
};

module.exports = config;
